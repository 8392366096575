<template>
    <section class="vh-100">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 px-0 d-none d-lg-block d-md-block">
                    <b-img src="../../assets/img/IconoPrincipal.png" alt="Login image" class="w-100 vh-100" style="object-fit: cover;"/>
                </div>
                <b-col cols="12" sm="12" md="6" lg="6">
                    <div class="container mt-1" >
                        <b-row align-h="end">
                            <b-img class="small_hearth" src="../../assets/heart_red.svg"/>
                        </b-row>
                        <div class="row justify-content-center mx-auto">
                            <div class="mx-auto" style="width: auto; margin: auto auto auto auto;">
                                <b-img class="mx-auto" src="../../assets/logos/lopez_orozco.png" style="max-height: 300px; max-width: 300px;"/>
                                <h3 class="title_slogan">Tecnología en salud</h3>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div>
                                <b-form ref="definirPsw" v-on:submit.prevent>
                                    <b-row class="form_mt justify-content-center">
                                        <b-col cols="12" sm="12" md="12" lg="8">
                                            <h3 class="txt_red text-center mb-3">¡Comencemos!</h3>
                                            <label class="icon_blue mb-1 ml-4"><strong>Correo electrónico</strong></label>
                                            <div class="d-flex">
                                                <i class="fa fa-user fa-lg my-auto mr-2 icon_blue" />
                                                <b-form-input v-model="data.email" class="form_input-color border-0" size="md" placeholder="Ingresa tu correo electónico"></b-form-input>
                                            </div>
                                            <div>
                                                <span class="msjError ml-4" >{{msjError}}</span>
                                            </div>
                                        </b-col>
                                        <b-col class="mt-3" cols="12" sm="12" md="12" lg="8">
                                            <label class="icon_blue mb-1 ml-4"><strong>Crear contraseña</strong></label>
                                            <div class="d-flex">
                                                <i class="fa fa-lock fa-lg my-auto mr-2 icon_blue" />
                                                <b-form-input v-model="data.psw1" class="form_input-color border-0" size="md" type="password" placeholder="Ingresa tu contraseña"></b-form-input>
                                            </div>
                                            <div class="my-0 py-0">
                                                <span class="msjError ml-4" >{{msjError2}}</span>
                                            </div>
                                        </b-col>
                                        <b-col class="mt-3" cols="12" sm="12" md="12" lg="8">
                                            <label class="label-color mb-1 ml-4"><strong>Verificar contraseña</strong></label>
                                            <div class="d-flex">
                                                <i class="icon_blue fa fa-lock fa-lg  my-auto mr-2"></i>
                                                <b-form-input v-model="data.psw2" class="form_input-color border-0 psw" size="md" type="password" placeholder="Ingresa tu contraseña"></b-form-input>
                                            </div>
                                            <span  @click="showPassword()" class="icon_float fa fa-eye fa-lg change_icon"></span>
                                            <div class="my-0">
                                                <span class="msjError ml-4" >{{msjError3}}</span>
                                            </div>
                                        </b-col>
                                    </b-row>
                                    <b-row class="justify-content-center mt-3">
                                        <b-col cols="12" sm="12" md="12" lg="8">
                                            <b-form-checkbox class="ml-4">
                                                <span class="checkBox">Recordarme</span>
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row class="justify-content-center">
                                        <b-col class="mt-4 ml-5" cols="12" sm="12" md="12" lg="8">
                                            <div>
                                                <b-overlay
                                                :show="busy"
                                                rounded
                                                opacity="0.6"
                                                spinner-small
                                                spinner-variant="primary"
                                                class="d-inline-block"
                                                @hidden="onHidden"
                                                >
                                                    <b-button variant="bg_blue" class="bg_btn_form border-0 py-2 pl-4 pr-4" ref="button" :disabled="busy" type="submit" @click="iniciarSesion()">
                                                        INICIAR SESIÓN
                                                    </b-button>
                                                </b-overlay>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </div>
                        </div>
                    </div>
                </b-col>
            </div>
        </div>
    </section>
</template>

<script>
const v_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default {
    name: 'Definir',

    data:() => ({
        busy: false,
        msjError: '',
        msjError2: '',
        msjError3: '',
        data: {
            email: '',
            psw1: '',
            psw2: ''
        }
    }),

    methods: {
        onHidden() {
            this.$refs.button.focus()
        },

        iniciarSesion() {
            /* const formData = {
                email: this.data.email,
                psw1: this.data.psw1,
                psw2: this.data.psw2
            }   */ 

            if (!this.data.email) {
                this.msjError = 'El correo es obligatorio';
            } else if(!v_email.test(this.data.email)) {
                this.msjError3 = '';
                this.msjError2 = '';
                this.msjError = 'Correo invalido'
            } else if(!this.data.psw1) {
                this.msjError = '';
                this.msjError2 = 'La contraseña es obligatoria';
            } else if(!this.data.psw2) {
                this.msjError = '';
                this.msjError2 = '';
                this.msjError3 = 'La contraseña es obligatoria';
            } else if(this.data.psw2 != this.data.psw1) {
                this.msjError = '';
                this.msjError2 = '';
                this.msjError3 = 'Las contraseñas deben de coicidir';
            } else {
                this.msjError = '';
                this.msjError2 = '';
                this.msjError3 = '';
                console.log('llegue');
            }
        },

        showPassword() {
            const psw = document.querySelector('.psw');
            const icon = document.querySelector('.change_icon');

            if (psw.type === "text") {
                psw.type = "password";
                icon.classList.remove("fa-eye-slash");
            } else {
                psw.type = "text";
                icon.classList.toggle("fa-eye-slash");
            }
        }
    }

}
</script>

<style scoped>
    .checkBox {
        color: #666666;
    }
    .form_mt {
        margin-top: 16px;
    }
</style>